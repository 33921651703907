


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {maxLength} from 'vuelidate/lib/validators';
import BhInput from '@/components/BhInput.vue';

Vue.use(Vuelidate);

@Component({
  components: {BhInput},
  validations: {
    form: {
      otherEthnicity: {maxLength: maxLength(25)},
    },
  },
})
export default class ProfileEthnicity extends Vue {
  @Prop({type: String, default: 'Choose your ethnicity'}) readonly title!: string;
  @Prop({type: Object, required: true}) readonly ethnicityEnum!: {[key: string]: string};

  form = {
    ethnicity: '',
    mixedEthnicity: {} as {[key: string]: boolean},
    otherEthnicity: '',
  };

  get ethnicityErrorMessage(): string {
    if (this.$v.form.otherEthnicity?.$error) {
      return `Ethnicity should be up to ${this.$v.form.otherEthnicity?.$params.maxLength.max} symbols long`;
    }
    return 'This field should not be empty';
  }

  created() {
    const mixedEthnicity = {} as {[key: string]: boolean};
    Object.keys(this.ethnicityEnum).forEach((key) => {
      if (key !== 'mix' && key !== 'other') {
        mixedEthnicity[key] = false;
      }
    });
    this.form.mixedEthnicity = mixedEthnicity;
    // this.passUpdatedEthnicity();
  }

  @Watch('form', {deep: true})
  onFormChanged() {
    this.passUpdatedEthnicity();
  }

  passUpdatedEthnicity(): void {
    const params = {
      ethnicity: this.form.ethnicity,
      ...(this.form.ethnicity === this.ethnicityEnum.other && {otherEthnicity: this.form.otherEthnicity}),
      ...(this.form.ethnicity === this.ethnicityEnum.mix && {mixEthnicity: this.getMixedEthnicityParams()}),
      gotErrors: false,
    };
    params.gotErrors = this.gotErrors(params);
    this.$emit('profileEthnicityUpdated', params);
  }
  getMixedEthnicityParams(): string[] {
    const mixedEthnicity = [] as string[];
    Object.keys(this.form.mixedEthnicity).forEach((key) => {
      if (this.form.mixedEthnicity[key]) {
        mixedEthnicity.push(this.ethnicityEnum[key]);
      }
    });
    return mixedEthnicity;
  }
  gotErrors(params: {
    gotErrors: boolean;
    mixEthnicity?: string[];
    otherEthnicity?: string;
    ethnicity: string;
  }): boolean {
    return (
      !params.ethnicity ||
      (params.ethnicity === this.ethnicityEnum.mix && (!params.mixEthnicity || params.mixEthnicity.length < 2)) ||
      (params.ethnicity === this.ethnicityEnum.other && (this.$v.form.otherEthnicity!.$error || !params.otherEthnicity))
    );
  }
}
