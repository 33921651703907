



























































































































































































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vuelidate from 'vuelidate';
import {email, helpers, maxLength, minLength, required, sameAs} from 'vuelidate/lib/validators';
import moment from 'moment';
import {vxm} from '@/store';
import BhInput from '@/components/BhInput.vue';
import BhSelect from '@/components/BhSelect.vue';
import InvitationLanding from '@/components/InvitationLanding.vue';
import Loader from '@/components/Loader.vue';
import ProfileWatchPreferences from '@/components/ProfileWatchPreferences.vue';
import ProfileActivityPreferences from '@/components/ProfileActivityPreferences.vue';
import ProfileEthnicity from '@/components/ProfileEthnicity.vue';
import usernameRegexp, {maxUsernameLength, minUsernameLength} from '@/constants/usernameRegex';
import CroppieMixin from '@/mixins/CroppieMixin';
import BlobToFileMixin from '@/mixins/BlobToFileMixin';
import UsernamePrepareMixin from '@/mixins/UsernamePrepareMixin';
import debounce from '@/utils/debounce';
import {hasRestrictedWords, matchRestrictedWord} from '@/constants/restrictedWordsValidation';
import MobileDatePicker from '@/components/MobileDatePicker.vue';
import IsMobileMixin from '@/mixins/isMobileMixin';
import nameRegexp, {maxNameLength, minNameLength} from '@/constants/nameRegex';

Vue.use(Vuelidate);

@Component({
  components: {
    MobileDatePicker,
    VueRecaptcha,
    BhInput,
    BhSelect,
    DatePicker,
    InvitationLanding,
    Loader,
    ProfileWatchPreferences,
    ProfileActivityPreferences,
    ProfileEthnicity,
  },
  validations: {
    form: {
      email: {required, email},
    },
    form1: {
      name: {
        required,
        alpha: helpers.regex('alpha', nameRegexp),
        minLength: minLength(minNameLength),
        maxLength: maxLength(maxNameLength),
        hasRestrictedWords,
      },
      username: {
        required,
        alpha: helpers.regex('alpha', usernameRegexp),
        minLength: minLength(minUsernameLength),
        maxLength: maxLength(maxUsernameLength),
        hasRestrictedWords,
      },
      birthDate: {required},
      gender: {required},
      location: {minLength: minLength(3), maxLength: maxLength(25)},
      isAdult: {required, sameAs: sameAs(() => true)},
    },
    form6: {
      password: {
        required,
        minLength: minLength(8),
        alpha: helpers.regex('alpha', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,64}$/),
      },
      repeat: {required, sameAs: sameAs('password')},
      agree: {required, sameAs: sameAs(() => true)},
    },
  },
})
export default class SignUp extends Mixins(CroppieMixin, BlobToFileMixin, UsernamePrepareMixin, IsMobileMixin) {
  loading = false;
  form = {
    email: '',
    'g-recaptcha-response': '',
  };
  form1 = {
    name: '',
    username: '',
    birthDate: '',
    gender: '',
    location: '',
    isAdult: false,
  };

  form3 = {
    wantToSee: {} as {[key: string]: string[] | undefined},
    otherWantToSee: undefined as undefined | string,
  };
  form3Errors = false;

  form4 = {
    wantToPost: {} as {[key: string]: string | undefined},
    otherWantToPost: undefined as undefined | string,
  };
  form4Errors = false;

  form5 = {
    ethnicity: '',
    mixEthnicity: undefined as string[] | undefined,
    otherEthnicity: undefined as undefined | string,
  };
  form5Errors = false;

  form6 = {
    password: '',
    repeat: '',
    private: false,
    agree: false,
  };

  regStep = 0;
  steps = 6;
  showPass = false;
  showPass2 = false;
  verifyError = false;
  captcha = false;
  confirmScreen = false;
  submitted = false;
  accessToken = '';
  inviteToken = '';
  underage = true;
  showInvitationLanding = false;
  invitationInfo = {};
  usernameIsFree = true;
  form5MEErrors = false;
  debounceHandler = () => {
    /* Will be replaced */
  };
  enums = null as null | {[key: string]: {[key: string]: string} | string};

  get btnText() {
    if (this.regStep === 0) {
      return 'Confirm email';
    } else if (!this.cropBlock && !this.cropped && this.regStep === 2) {
      return 'Skip';
    } else if (this.cropBlock) {
      return 'Save';
    } else if (this.regStep === 6) {
      return 'Sign up';
    } else {
      return 'Next';
    }
  }

  get passwordErrorMessage(): string {
    if (this.form6.password.includes(' ')) {
      return 'Password must not contain spaces';
    }
    if (!this.$v.form6.password?.required) {
      return 'Password is a required field';
    }
    if (!this.$v.form6.password?.minLength) {
      return `Password should be at least ${this.$v.form6.password?.$params.minLength.min} symbols long`;
    }
    if (!this.$v.form6.password?.alpha) {
      return 'At least one capital letter, one digit and one special symbol';
    }
    return '';
  }

  get nameErrorMessage(): string {
    if (!this.$v.form1.name?.required) {
      return 'Name is a required field';
    }
    if (!this.$v.form1.name?.alpha) {
      return 'Name should contain only lowercase, uppercase letters and hyphens';
    }
    if (!this.$v.form1.name?.hasRestrictedWords) {
      return 'Input contains restricted word: ' + matchRestrictedWord(this.$v.form1.name.$model);
    }
    return `Name should be ${minNameLength}-${maxNameLength} symbols long`;
  }

  get usernameErrorMessage(): string {
    if (!this.$v.form1.username?.required) {
      return 'Username is a required field';
    }
    if (!this.$v.form1.username?.alpha) {
      return 'Username should contain only lowercase letters, digits and hyphens';
    }
    if (!this.usernameIsFree) {
      return 'Username is already taken';
    }
    if (!this.$v.form1.username?.hasRestrictedWords) {
      return 'Input contains restricted word: ' + matchRestrictedWord(this.$v.form1.username.$model);
    }
    return `Username should be ${minUsernameLength}-${maxUsernameLength} symbols long`;
  }

  get locationErrorMessage(): string {
    return `Location should be ${this.$v.form1.location?.$params.minLength.min}\
-${this.$v.form1.location?.$params.maxLength.max} symbols long`;
  }

  get gotEnums(): boolean {
    return !!(this.enums && this.enums.gender && this.enums.wantToSee && this.enums.wantToPost);
  }

  @Watch('form1.username')
  onUsernameChanged() {
    this.form1.username = this.form1.username.toLowerCase();
    this.debounceHandler();
  }

  mounted() {
    if (this.$route.query.inviteToken) {
      this.inviteToken = (this.$route.query.inviteToken as string) || '';
      this.checkInviteToken();
    } else if (this.$route.query.accessToken) {
      this.accessToken = (this.$route.query.accessToken as string) || '';
      if (this.accessToken) {
        this.getEnums();
      }
      this.inviteToken = this.$route.params.token || '';
      this.regStep = 1;
      vxm.user.getRestrictedWords(this.accessToken);
    } else {
      this.$router.push({name: 'landing'});
    }
    this.debounceHandler = debounce(this.onUsernameInput, 500);
  }

  getEnums() {
    this.loading = true;
    vxm.user
      .getEnums(this.accessToken)
      .then((res: {data: {data: any}}) => {
        this.enums = res.data.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  checkInviteToken() {
    this.loading = true;
    vxm.invitations
      .checkInvitation(this.inviteToken)
      .then((res: {data: {valid: boolean; inviter: {}}}) => {
        this.invitationInfo = res.data;
        this.showInvitationLanding = true;
      })
      .catch(() => {
        this.$router.push('/landing');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onInvitationAccepted() {
    this.showInvitationLanding = false;
  }

  detectAge() {
    const birthday = moment(this.form1.birthDate, 'D MMMM YYYY');
    const age = moment.duration(moment().diff(birthday)).asYears();
    this.underage = age < 18;
  }

  stepController() {
    if (this.regStep === 0) {
      this.signUpVerify();
    } else if (this.regStep === 2 && this.cropBlock) {
      this.crop();
    } else if (this.regStep === 2 && !this.cropBlock) {
      this.regStep = 3;
    } else {
      this.goToNextStep(this.regStep);
    }
  }

  onBackClicked() {
    if (this.regStep === 2 && this.cropBlock) {
      this.cropBlock = false;
    }
    this.regStep -= 1;
  }

  signUpVerify() {
    this.$v.form.$touch();
    if (this.$v.form.$invalid) {
      return;
    }
    this.loading = true;
    vxm.invitations
      .signUpVerifyEmail({email: this.form.email, inviteToken: this.inviteToken})
      .then(() => {
        this.confirmScreen = true;
      })
      .catch(() => {
        this.verifyError = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async signUp() {
    let mediaId = '';
    if (this.cropped) {
      const media = await this.getFileFromDataUrl(this.cropped);
      const formData = new FormData();
      formData.append('files', media);
      this.loading = true;
      await vxm.user
        .addAvatarOnRegistration({files: [media], accessToken: this.accessToken})
        .then((res: {data: {data: {_id: string}[]}}) => {
          mediaId = res.data.data[0]._id;
        });
    }
    const params = {
      ...(this.inviteToken && {inviteToken: this.inviteToken}),
      name: this.form1.name,
      username: this.form1.username,
      birthDate: this.form1.birthDate,
      gender: this.form1.gender,
      ...(this.form1.location && {location: this.form1.location}),
      isAdult: this.form1.isAdult,
      ...(!this.form3Errors && {...this.form3}),
      ...(!this.form4Errors && {...this.form4}),
      ...(!this.form5Errors && {...this.form5}),
      password: this.form6.password,
      password2: this.form6.repeat,
      isPrivate: this.form6.private,
      isAgree: this.form6.agree,
      ...(mediaId && {mediaId}),
    };
    vxm.user
      .signUp({data: params, accessToken: this.accessToken})
      .then(() => {
        this.submitted = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onVerify(e: string) {
    this.form['g-recaptcha-response'] = e;
    this.captcha = true;
  }

  async goToNextStep(regStep: number) {
    if (regStep > 2 && regStep < 6) {
      this.regStep++;
      return;
    }
    const form = 'form' + regStep;
    this.$v[form].$touch();
    if (!this.$v[form].$invalid) {
      if (this.regStep === 6) {
        await this.signUp();
      }
      this.regStep++;
    }
  }

  getFileFromDataUrl(dataUrl: string) {
    return fetch(dataUrl).then((res) => {
      return res.blob().then((res) => {
        return new File([res], 'cover.img', {type: 'image/jpeg'});
      });
    });
  }

  removeImage() {
    this.cropped = '';
    this.croppieImage = '';
  }

  onUsernameInput(): void {
    this.form1.username = this.getPreparedUsername(this.form1.username);
    this.$v.form1.username?.$touch();
    if (this.$v.form1.username?.$error) {
      return;
    }
    vxm.user
      .checkUsernameUniqueness(this.form1.username)
      .then(() => {
        this.usernameIsFree = true;
      })
      .catch(() => {
        this.usernameIsFree = false;
      });
  }

  onWatchPreferencesUpdated(payload: {
    gotErrors: boolean;
    otherWantToSee?: string;
    wantToSee: {[key: string]: string[] | undefined};
  }): void {
    this.form3Errors = payload.gotErrors;
    this.form3.wantToSee = payload.wantToSee;
    this.form3.otherWantToSee = payload.otherWantToSee;
  }
  onActivityPreferencesUpdated(payload: {
    gotErrors: boolean;
    otherWantToPost?: string;
    wantToPost: {[key: string]: string | undefined};
  }): void {
    this.form4Errors = payload.gotErrors;
    this.form4.wantToPost = payload.wantToPost;
    this.form4.otherWantToPost = payload.otherWantToPost;
  }
  onEthnicityUpdated(payload: {
    gotErrors: boolean;
    ethnicity: string;
    mixEthnicity?: string[];
    otherEthnicity?: string;
  }): void {
    this.form5Errors = payload.gotErrors;
    this.form5.ethnicity = payload.ethnicity;
    this.form5.mixEthnicity = payload.mixEthnicity;
    this.form5.otherEthnicity = payload.otherEthnicity;
    this.form5MEErrors = !!(
      payload.ethnicity === 'Mixed' &&
      this.form5.mixEthnicity &&
      this.form5.mixEthnicity?.length < 2
    );
  }
}
