

















import {Component, Prop, Vue} from 'vue-property-decorator';
import NoContent from '@/components/NoContent.vue';
import ImageLinkSizesInterface from '@/types/ImageLinkSizesInterface';

@Component({
  components: {NoContent},
})
export default class InvitationLanding extends Vue {
  @Prop() readonly invitationInfo!: {
    valid: true;
    inviter: {name: string; avatar: {link: ImageLinkSizesInterface} | null};
  };

  expiredMessage = 'Your invitation is expired...';

  getInviterAvatar(): string {
    return this.invitationInfo.inviter.avatar && this.invitationInfo.inviter.avatar.link
      ? this.invitationInfo.inviter.avatar.link.medium || this.invitationInfo.inviter.avatar.link.origin
      : require('@/assets/icons/avatar-default.svg');
  }
  onProceed(): void {
    this.$emit('invitationAccepted');
  }
  onClose(): void {
    this.$router.push({name: 'landing'});
  }
}
